import { sum } from 'lodash';

import { Nullable } from '@/types';
import { KpiBox_node_Viewer_stats_rows } from './__generated__/KpiBox';

export const getSumOfRow = (
  metrics: Nullable<string[]>,
  row: Nullable<KpiBox_node_Viewer_stats_rows>,
): number | undefined => {
  if (row) {
    return sum(metrics?.map((metric) => getValue(metric, row)));
  }
  return undefined;
};

export const getValue = (
  metric: Nullable<string>,
  row: Nullable<KpiBox_node_Viewer_stats_rows>,
): number | undefined => {
  if (row) {
    const value = row[metric as keyof typeof row];
    return typeof value === 'number' ? value : undefined;
  }
  return undefined;
};

export const getValueDifference = (
  value: Nullable<number>,
  lastValue: Nullable<number>,
) => {
  if (lastValue && value) {
    return value - lastValue;
  }
  return undefined;
};

export const metricLabelMap: Record<string, string> = {
  ngr: 'NGR',
  ggr: 'GGR',
  amountDeposits: 'Deposit Amount',
  amountWithdrawals: 'Withdrawal Amount',
  amountAdjustments: 'Adjustment Amount',
  amountBonusAdjustments: 'Bonus Adjustment Amount',
  hold: 'Hold',
};
