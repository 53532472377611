import loadable from '@loadable/component';
import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import { last } from 'lodash/fp';
import React, { FC, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  ControlledModal,
  Copy,
  InlineIconButton,
  Tippy,
  Value,
} from '@/components';
import { InformationIcon } from '@/components/icons';
import { useTime, useTranslate, useViewer } from '@/contexts';
import { predefinedTimeFrames } from '@/contexts/TimeContext/predefinedTimeFrames';
import { Nullable } from '@/types';
import { mapVariables } from '@/utils';
import formatMoney from '@/utils/formatter/formatMoney';
import { withKeyByPlayerIdParam } from '@/utils/withKeyByParam';
import { KpiBox, KpiBoxVariables } from './__generated__/KpiBox';
import { SanityKpiBlockFragment } from './__generated__/SanityKpiBlockFragment';
import { getSumOfRow, getValueDifference } from './helpers';

const KpiDetails = loadable(() => import('./KpiDetails'));

export const Fragment = graphql`
  fragment SanityKpiBlockFragment on SanityKpiBlock {
    title {
      ...LocaleString
    }
    metric
    metricV2
  }
`;

const statsFragment = gql`
  fragment KpiStatsFragment on StatsType {
    rows {
      currency @include(if: $requireCurrency)
      ngr @include(if: $ngr)
      ggr @include(if: $ggr)
      amountDeposits @include(if: $amountDeposits)
      amountWithdrawals @include(if: $amountWithdrawals)
      amountAdjustments @include(if: $amountAdjustments)
      amountBonusAdjustments @include(if: $amountBonusAdjustments)
      hold: hold_ @include(if: $hold)
    }
    error
  }
`;

const QUERY = gql`
  query KpiBox(
    $entityId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
    $allTimeFrom: OffsetDateTime!
    $allTimeTo: OffsetDateTime!
    $includeAllTime: Boolean!
    $ngr: Boolean!
    $ggr: Boolean!
    $amountDeposits: Boolean!
    $amountWithdrawals: Boolean!
    $amountAdjustments: Boolean!
    $amountBonusAdjustments: Boolean!
    $hold: Boolean!
    $timeZone: String
    $requireCurrency: Boolean!
  ) {
    node(id: $entityId) {
      id
      __typename
      ... on EntityWithStatistics {
        stats(
          granularity: All
          from: $from
          to: $to
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) {
          ...KpiStatsFragment
        }
        allTimeStats: stats(
          granularity: All
          from: $allTimeFrom
          to: $allTimeTo
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) @include(if: $includeAllTime) {
          ...KpiStatsFragment
        }
      }
    }
  }
  ${statsFragment}
`;

const getBaseNode = (data: Nullable<KpiBox>) => {
  if (
    data?.node?.__typename === 'Viewer' ||
    data?.node?.__typename === 'Player'
  ) {
    return data.node;
  }
  return null;
};

function getOr<Value>(
  value: Value,
  defaultValue: NonNullable<Value>,
): NonNullable<Value> {
  if (value != null) {
    return value as NonNullable<Value>;
  }
  return defaultValue;
}

const getMetrics = (block: SanityKpiBlockFragment) =>
  block.metricV2?.split(',') ?? [];

const KpiBlock: FC<{
  block: SanityKpiBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const { viewer } = useViewer();
  const { timeFrame, time } = useTime();

  const [previous, setPrevious] = useState<(number | undefined)[]>([]);

  const timeVariables = mapVariables(time);
  const allTime = mapVariables(predefinedTimeFrames['all-time'].getInterval());

  const isAllTimeSelected =
    timeFrame.name === predefinedTimeFrames['all-time'].name;

  const variables = useMemo(() => {
    return {
      entityId: params.playerId || viewer?.id || '',
      ...timeVariables,
      allTimeFrom: allTime.from,
      allTimeTo: allTime.to,
      includeAllTime: !isAllTimeSelected,
      ggr: false,
      ngr: false,
      amountDeposits: false,
      amountWithdrawals: false,
      amountAdjustments: false,
      amountBonusAdjustments: false,
      hold: false,
      requireCurrency: !!params.playerId,
      ...block.metricV2
        ?.split(',')
        .reduce<Record<string, true>>(
          (acc, metricKey) => ({ ...acc, [metricKey]: true }),
          {},
        ),
    };
  }, [
    allTime.from,
    allTime.to,
    block.metricV2,
    isAllTimeSelected,
    params.playerId,
    timeVariables,
    viewer?.id,
  ]);

  const [{ data, fetching }] = useQuery<KpiBox, KpiBoxVariables>({
    query: QUERY,
    variables,
    // @ts-expect-error
    pollInterval: timeFrame.isRealTime ? 5000 : undefined,
    requestPolicy: 'cache-and-network',
  });

  const baseNode = getBaseNode(data);

  const row = baseNode?.stats?.rows[0];
  const allTimeRow = baseNode?.allTimeStats?.rows[0];

  const metrics = getMetrics(block);

  const combinedValue = getSumOfRow(metrics, row);
  const allTimeCombinedValue = getSumOfRow(metrics, allTimeRow);

  const lastValue = getOr(previous[0], 0);
  const currency = row?.currency;
  const valueChange = getValueDifference(combinedValue, lastValue);

  useEffect(() => {
    setPrevious([]);
  }, [time]);

  useEffect(() => {
    setPrevious((prev) => [last(prev), combinedValue]);
  }, [combinedValue]);

  return (
    <Card size="sm">
      <CardBody>
        <div className="p-3">
          <Value
            title={
              <div className="flex justify-between">
                <span>{t(block.title)}</span>
                <ControlledModal
                  content={
                    <KpiDetails
                      metrics={metrics}
                      row={row}
                      allTimeRow={isAllTimeSelected ? row : allTimeRow}
                      currency={currency}
                      block={block}
                    />
                  }
                >
                  <InlineIconButton>
                    <InformationIcon />
                  </InlineIconButton>
                </ControlledModal>
              </div>
            }
            fetching={fetching}
            error={baseNode?.stats?.error !== null}
          >
            <Tippy content="All time">
              <div>
                <Copy
                  value={formatMoney(
                    // show "value" when all time is selected since we don't fetch allTimeValue at that time
                    (isAllTimeSelected
                      ? combinedValue
                      : allTimeCombinedValue) ?? 0,
                    currency,
                  )}
                />
              </div>
            </Tippy>
            <Tippy content="Selected period">
              <div className="text-sm">
                {baseNode ? (
                  <Copy
                    value={formatMoney(getOr(combinedValue, 0), currency)}
                  />
                ) : (
                  <>-</>
                )}
              </div>
            </Tippy>
            <Tippy content="Value change in last seconds (when using live data)">
              <div className="text-sm">
                {valueChange ? (
                  valueChange >= 0 ? (
                    <span className="text-green-500">
                      +{formatMoney(valueChange, currency)}
                    </span>
                  ) : (
                    <span className="text-red-500">
                      {formatMoney(valueChange, currency)}
                    </span>
                  )
                ) : (
                  '-'
                )}
              </div>
            </Tippy>
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default withKeyByPlayerIdParam(KpiBlock);
