import { useParams } from '@reach/router';
import React, { ComponentType } from 'react';

export function withKeyByParams(paramKeys: string[]) {
  return function componentWithKeyByParams<Props>(C: ComponentType<Props>) {
    const ComponentWithKeyByParams = (props: Props) => {
      const params = useParams();

      const combinedKey = paramKeys.map((k) => `${k}=${params[k]}`).join(';');

      return <C key={combinedKey} {...props} />;
    };

    return ComponentWithKeyByParams;
  };
}

export const withKeyByPlayerIdParam = withKeyByParams(['playerId']);
